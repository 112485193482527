import { derived, writable } from 'svelte/store';
import { cloneDate, subtractDay, addDay, setContent, keyEnter, helperEvent, toEventWithLocalDates, toViewWithLocalDates, task, createEventClasses, createEventContent, rect, ancestor, repositionEvent, height, max, toISOString, outsideEvent, datesEqual, assign, createEventChunk, runReposition, setPayload, bgEvent, eventIntersects, prepareEventChunks, debounce, btnTextMonth, themeView, intl } from '@event-calendar/core';
import { SvelteComponent, init, safe_not_equal, ensure_array_like, element, space, attr, insert, append, noop, detach, destroy_each, component_subscribe, action_destroyer, is_function, construct_svelte_component, create_component, mount_component, listen, group_outros, transition_out, destroy_component, check_outros, transition_in, run_all, binding_callbacks, text, stop_propagation, update_keyed_each, outro_and_destroy_block, empty, set_store_value, bubble } from 'svelte/internal';
import { getContext, onMount, afterUpdate, tick } from 'svelte';

function days(state) {
    return derived([state.date, state.firstDay, state.hiddenDays], ([$date, $firstDay, $hiddenDays]) => {
        let days = [];
        let day = cloneDate($date);
        let max = 7;
        // First day of week
        while (day.getUTCDay() !== $firstDay && max) {
            subtractDay(day);
            --max;
        }
        for (let i = 0; i < 7; ++i) {
            if (!$hiddenDays.includes(day.getUTCDay())) {
                days.push(cloneDate(day));
            }
            addDay(day);
        }

        return days;
    });
}

/* packages/day-grid/src/Header.svelte generated by Svelte v4.2.16 */

function get_each_context$4(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[8] = list[i];
	return child_ctx;
}

// (10:8) {#each $_days as day}
function create_each_block$4(ctx) {
	let div;
	let span;
	let span_aria_label_value;
	let setContent_action;
	let t;
	let div_class_value;
	let mounted;
	let dispose;

	return {
		c() {
			div = element("div");
			span = element("span");
			t = space();
			attr(span, "aria-label", span_aria_label_value = /*$_intlDayHeaderAL*/ ctx[2].format(/*day*/ ctx[8]));
			attr(div, "class", div_class_value = "" + (/*$theme*/ ctx[0].day + " " + /*$theme*/ ctx[0].weekdays?.[/*day*/ ctx[8].getUTCDay()]));
			attr(div, "role", "columnheader");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, span);
			append(div, t);

			if (!mounted) {
				dispose = action_destroyer(setContent_action = setContent.call(null, span, /*$_intlDayHeader*/ ctx[3].format(/*day*/ ctx[8])));
				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;

			if (dirty & /*$_intlDayHeaderAL, $_days*/ 6 && span_aria_label_value !== (span_aria_label_value = /*$_intlDayHeaderAL*/ ctx[2].format(/*day*/ ctx[8]))) {
				attr(span, "aria-label", span_aria_label_value);
			}

			if (setContent_action && is_function(setContent_action.update) && dirty & /*$_intlDayHeader, $_days*/ 10) setContent_action.update.call(null, /*$_intlDayHeader*/ ctx[3].format(/*day*/ ctx[8]));

			if (dirty & /*$theme, $_days*/ 3 && div_class_value !== (div_class_value = "" + (/*$theme*/ ctx[0].day + " " + /*$theme*/ ctx[0].weekdays?.[/*day*/ ctx[8].getUTCDay()]))) {
				attr(div, "class", div_class_value);
			}
		},
		d(detaching) {
			if (detaching) {
				detach(div);
			}

			mounted = false;
			dispose();
		}
	};
}

function create_fragment$6(ctx) {
	let div2;
	let div0;
	let div0_class_value;
	let t;
	let div1;
	let div1_class_value;
	let div2_class_value;
	let each_value = ensure_array_like(/*$_days*/ ctx[1]);
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block$4(get_each_context$4(ctx, each_value, i));
	}

	return {
		c() {
			div2 = element("div");
			div0 = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t = space();
			div1 = element("div");
			attr(div0, "class", div0_class_value = /*$theme*/ ctx[0].days);
			attr(div0, "role", "row");
			attr(div1, "class", div1_class_value = /*$theme*/ ctx[0].hiddenScroll);
			attr(div2, "class", div2_class_value = /*$theme*/ ctx[0].header);
		},
		m(target, anchor) {
			insert(target, div2, anchor);
			append(div2, div0);

			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(div0, null);
				}
			}

			append(div2, t);
			append(div2, div1);
		},
		p(ctx, [dirty]) {
			if (dirty & /*$theme, $_days, $_intlDayHeaderAL, $_intlDayHeader*/ 15) {
				each_value = ensure_array_like(/*$_days*/ ctx[1]);
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context$4(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block$4(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(div0, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}

			if (dirty & /*$theme*/ 1 && div0_class_value !== (div0_class_value = /*$theme*/ ctx[0].days)) {
				attr(div0, "class", div0_class_value);
			}

			if (dirty & /*$theme*/ 1 && div1_class_value !== (div1_class_value = /*$theme*/ ctx[0].hiddenScroll)) {
				attr(div1, "class", div1_class_value);
			}

			if (dirty & /*$theme*/ 1 && div2_class_value !== (div2_class_value = /*$theme*/ ctx[0].header)) {
				attr(div2, "class", div2_class_value);
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) {
				detach(div2);
			}

			destroy_each(each_blocks, detaching);
		}
	};
}

function instance$5($$self, $$props, $$invalidate) {
	let $theme;
	let $_days;
	let $_intlDayHeaderAL;
	let $_intlDayHeader;
	let { theme, _intlDayHeader, _intlDayHeaderAL, _days } = getContext('state');
	component_subscribe($$self, theme, value => $$invalidate(0, $theme = value));
	component_subscribe($$self, _intlDayHeader, value => $$invalidate(3, $_intlDayHeader = value));
	component_subscribe($$self, _intlDayHeaderAL, value => $$invalidate(2, $_intlDayHeaderAL = value));
	component_subscribe($$self, _days, value => $$invalidate(1, $_days = value));

	return [
		$theme,
		$_days,
		$_intlDayHeaderAL,
		$_intlDayHeader,
		theme,
		_intlDayHeader,
		_intlDayHeaderAL,
		_days
	];
}

class Header extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance$5, create_fragment$6, safe_not_equal, {});
	}
}

/* packages/day-grid/src/Event.svelte generated by Svelte v4.2.16 */

function create_fragment$5(ctx) {
	let article;
	let div;
	let div_class_value;
	let setContent_action;
	let t;
	let switch_instance;
	let article_role_value;
	let article_tabindex_value;
	let current;
	let mounted;
	let dispose;
	var switch_value = /*$_interaction*/ ctx[8].resizer;

	function switch_props(ctx, dirty) {
		return { props: { event: /*event*/ ctx[0] } };
	}

	if (switch_value) {
		switch_instance = construct_svelte_component(switch_value, switch_props(ctx));

		switch_instance.$on("pointerdown", function () {
			if (is_function(/*createDragHandler*/ ctx[33](/*$_interaction*/ ctx[8], 'x'))) /*createDragHandler*/ ctx[33](/*$_interaction*/ ctx[8], 'x').apply(this, arguments);
		});
	}

	return {
		c() {
			article = element("article");
			div = element("div");
			t = space();
			if (switch_instance) create_component(switch_instance.$$.fragment);
			attr(div, "class", div_class_value = /*$theme*/ ctx[2].eventBody);
			attr(article, "class", /*classes*/ ctx[4]);
			attr(article, "style", /*style*/ ctx[5]);
			attr(article, "role", article_role_value = /*onclick*/ ctx[7] ? 'button' : undefined);
			attr(article, "tabindex", article_tabindex_value = /*onclick*/ ctx[7] ? 0 : undefined);
		},
		m(target, anchor) {
			insert(target, article, anchor);
			append(article, div);
			append(article, t);
			if (switch_instance) mount_component(switch_instance, article, null);
			/*article_binding*/ ctx[53](article);
			current = true;

			if (!mounted) {
				dispose = [
					action_destroyer(setContent_action = setContent.call(null, div, /*content*/ ctx[6])),
					listen(article, "click", function () {
						if (is_function(/*onclick*/ ctx[7] || undefined)) (/*onclick*/ ctx[7] || undefined).apply(this, arguments);
					}),
					listen(article, "keydown", function () {
						if (is_function(/*onclick*/ ctx[7] && keyEnter(/*onclick*/ ctx[7]))) (/*onclick*/ ctx[7] && keyEnter(/*onclick*/ ctx[7])).apply(this, arguments);
					}),
					listen(article, "mouseenter", function () {
						if (is_function(/*createHandler*/ ctx[32](/*$eventMouseEnter*/ ctx[9], /*display*/ ctx[1]))) /*createHandler*/ ctx[32](/*$eventMouseEnter*/ ctx[9], /*display*/ ctx[1]).apply(this, arguments);
					}),
					listen(article, "mouseleave", function () {
						if (is_function(/*createHandler*/ ctx[32](/*$eventMouseLeave*/ ctx[10], /*display*/ ctx[1]))) /*createHandler*/ ctx[32](/*$eventMouseLeave*/ ctx[10], /*display*/ ctx[1]).apply(this, arguments);
					}),
					listen(article, "pointerdown", function () {
						if (is_function(!helperEvent(/*display*/ ctx[1]) && /*createDragHandler*/ ctx[33](/*$_interaction*/ ctx[8]))) (!helperEvent(/*display*/ ctx[1]) && /*createDragHandler*/ ctx[33](/*$_interaction*/ ctx[8])).apply(this, arguments);
					})
				];

				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;

			if (!current || dirty[0] & /*$theme*/ 4 && div_class_value !== (div_class_value = /*$theme*/ ctx[2].eventBody)) {
				attr(div, "class", div_class_value);
			}

			if (setContent_action && is_function(setContent_action.update) && dirty[0] & /*content*/ 64) setContent_action.update.call(null, /*content*/ ctx[6]);

			if (dirty[0] & /*$_interaction*/ 256 && switch_value !== (switch_value = /*$_interaction*/ ctx[8].resizer)) {
				if (switch_instance) {
					group_outros();
					const old_component = switch_instance;

					transition_out(old_component.$$.fragment, 1, 0, () => {
						destroy_component(old_component, 1);
					});

					check_outros();
				}

				if (switch_value) {
					switch_instance = construct_svelte_component(switch_value, switch_props(ctx));

					switch_instance.$on("pointerdown", function () {
						if (is_function(/*createDragHandler*/ ctx[33](/*$_interaction*/ ctx[8], 'x'))) /*createDragHandler*/ ctx[33](/*$_interaction*/ ctx[8], 'x').apply(this, arguments);
					});

					create_component(switch_instance.$$.fragment);
					transition_in(switch_instance.$$.fragment, 1);
					mount_component(switch_instance, article, null);
				} else {
					switch_instance = null;
				}
			} else if (switch_value) {
				const switch_instance_changes = {};
				if (dirty[0] & /*event*/ 1) switch_instance_changes.event = /*event*/ ctx[0];
				switch_instance.$set(switch_instance_changes);
			}

			if (!current || dirty[0] & /*classes*/ 16) {
				attr(article, "class", /*classes*/ ctx[4]);
			}

			if (!current || dirty[0] & /*style*/ 32) {
				attr(article, "style", /*style*/ ctx[5]);
			}

			if (!current || dirty[0] & /*onclick*/ 128 && article_role_value !== (article_role_value = /*onclick*/ ctx[7] ? 'button' : undefined)) {
				attr(article, "role", article_role_value);
			}

			if (!current || dirty[0] & /*onclick*/ 128 && article_tabindex_value !== (article_tabindex_value = /*onclick*/ ctx[7] ? 0 : undefined)) {
				attr(article, "tabindex", article_tabindex_value);
			}
		},
		i(local) {
			if (current) return;
			if (switch_instance) transition_in(switch_instance.$$.fragment, local);
			current = true;
		},
		o(local) {
			if (switch_instance) transition_out(switch_instance.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(article);
			}

			if (switch_instance) destroy_component(switch_instance);
			/*article_binding*/ ctx[53](null);
			mounted = false;
			run_all(dispose);
		}
	};
}

function instance$4($$self, $$props, $$invalidate) {
	let $eventClick;
	let $_hiddenEvents;
	let $dayMaxEvents;
	let $_popupDate;
	let $_interaction;
	let $_view;
	let $eventAllUpdated;
	let $eventDidMount;
	let $_intlEventTime;
	let $theme;
	let $eventContent;
	let $displayEventEnd;
	let $eventClassNames;
	let $_iClasses;
	let $eventTextColor;
	let $_resTxtColor;
	let $eventColor;
	let $eventBackgroundColor;
	let $_resBgColor;
	let $eventMouseEnter;
	let $eventMouseLeave;
	let { chunk } = $$props;
	let { longChunks = {} } = $$props;
	let { inPopup = false } = $$props;
	let { dates = [] } = $$props;
	let { dayMaxEvents, displayEventEnd, eventAllUpdated, eventBackgroundColor, eventTextColor, eventClick, eventColor, eventContent, eventClassNames, eventDidMount, eventMouseEnter, eventMouseLeave, theme, _view, _intlEventTime, _interaction, _iClasses, _resBgColor, _resTxtColor, _hiddenEvents, _popupDate, _tasks } = getContext('state');
	component_subscribe($$self, dayMaxEvents, value => $$invalidate(56, $dayMaxEvents = value));
	component_subscribe($$self, displayEventEnd, value => $$invalidate(45, $displayEventEnd = value));
	component_subscribe($$self, eventAllUpdated, value => $$invalidate(58, $eventAllUpdated = value));
	component_subscribe($$self, eventBackgroundColor, value => $$invalidate(51, $eventBackgroundColor = value));
	component_subscribe($$self, eventTextColor, value => $$invalidate(48, $eventTextColor = value));
	component_subscribe($$self, eventClick, value => $$invalidate(41, $eventClick = value));
	component_subscribe($$self, eventColor, value => $$invalidate(50, $eventColor = value));
	component_subscribe($$self, eventContent, value => $$invalidate(44, $eventContent = value));
	component_subscribe($$self, eventClassNames, value => $$invalidate(46, $eventClassNames = value));
	component_subscribe($$self, eventDidMount, value => $$invalidate(59, $eventDidMount = value));
	component_subscribe($$self, eventMouseEnter, value => $$invalidate(9, $eventMouseEnter = value));
	component_subscribe($$self, eventMouseLeave, value => $$invalidate(10, $eventMouseLeave = value));
	component_subscribe($$self, theme, value => $$invalidate(2, $theme = value));
	component_subscribe($$self, _view, value => $$invalidate(42, $_view = value));
	component_subscribe($$self, _intlEventTime, value => $$invalidate(43, $_intlEventTime = value));
	component_subscribe($$self, _interaction, value => $$invalidate(8, $_interaction = value));
	component_subscribe($$self, _iClasses, value => $$invalidate(47, $_iClasses = value));
	component_subscribe($$self, _resBgColor, value => $$invalidate(52, $_resBgColor = value));
	component_subscribe($$self, _resTxtColor, value => $$invalidate(49, $_resTxtColor = value));
	component_subscribe($$self, _hiddenEvents, value => $$invalidate(55, $_hiddenEvents = value));
	component_subscribe($$self, _popupDate, value => $$invalidate(57, $_popupDate = value));
	let el;
	let event;
	let classes;
	let style;
	let content;
	let timeText;
	let margin = 1;
	let hidden = false;
	let display;
	let onclick;

	onMount(() => {
		if (is_function($eventDidMount)) {
			$eventDidMount({
				event: toEventWithLocalDates(event),
				timeText,
				el,
				view: toViewWithLocalDates($_view)
			});
		}
	});

	afterUpdate(() => {
		if (is_function($eventAllUpdated) && !helperEvent(display)) {
			task(() => $eventAllUpdated({ view: toViewWithLocalDates($_view) }), 'eau', _tasks);
		}
	});

	function createHandler(fn, display) {
		return !helperEvent(display) && is_function(fn)
		? jsEvent => fn({
				event: toEventWithLocalDates(event),
				el,
				jsEvent,
				view: toViewWithLocalDates($_view)
			})
		: undefined;
	}

	function createDragHandler(interaction, resize) {
		return interaction.action
		? jsEvent => $_interaction.action.drag(event, jsEvent, resize, inPopup ? $_popupDate : null, [rect(el).top - rect(ancestor(el, 1)).top, dates])
		: undefined;
	}

	function reposition() {
		if (!el) {
			return;
		}

		$$invalidate(39, margin = repositionEvent(chunk, longChunks, height(el)));

		if ($dayMaxEvents === true) {
			hide();
		} else {
			$$invalidate(40, hidden = false);
		}
	}

	function hide() {
		let dayEl = ancestor(el, 2);
		let h = height(dayEl) - height(dayEl.firstElementChild) - footHeight(dayEl);
		$$invalidate(40, hidden = chunk.bottom > h);
		let update = false;

		// Hide or show the event throughout all days
		for (let date of chunk.dates) {
			let hiddenEvents = $_hiddenEvents[date.getTime()];

			if (hiddenEvents) {
				let size = hiddenEvents.size;

				if (hidden) {
					hiddenEvents.add(chunk.event);
				} else {
					hiddenEvents.delete(chunk.event);
				}

				if (size !== hiddenEvents.size) {
					update = true;
				}
			}
		}

		if (update) {
			_hiddenEvents.set($_hiddenEvents);
		}
	}

	function footHeight(dayEl) {
		let h = 0;

		for (let i = 0; i < chunk.days; ++i) {
			h = max(h, height(dayEl.lastElementChild));
			dayEl = dayEl.nextElementSibling;

			if (!dayEl) {
				break;
			}
		}

		return h;
	}

	function article_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			el = $$value;
			$$invalidate(3, el);
		});
	}

	$$self.$$set = $$props => {
		if ('chunk' in $$props) $$invalidate(34, chunk = $$props.chunk);
		if ('longChunks' in $$props) $$invalidate(35, longChunks = $$props.longChunks);
		if ('inPopup' in $$props) $$invalidate(36, inPopup = $$props.inPopup);
		if ('dates' in $$props) $$invalidate(37, dates = $$props.dates);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty[1] & /*chunk*/ 8) {
			$$invalidate(0, event = chunk.event);
		}

		if ($$self.$$.dirty[0] & /*event, style, $theme*/ 37 | $$self.$$.dirty[1] & /*$_resBgColor, $eventBackgroundColor, $eventColor, $_resTxtColor, $eventTextColor, margin, chunk, hidden, $_iClasses, $eventClassNames, $_view*/ 4164360) {
			{
				$$invalidate(1, display = event.display);

				// Class & Style
				let bgColor = event.backgroundColor || $_resBgColor(event) || $eventBackgroundColor || $eventColor;

				let txtColor = event.textColor || $_resTxtColor(event) || $eventTextColor;
				let marginTop = margin;

				if (event._margin) {
					// Force margin for helper events
					let [_margin, _dates] = event._margin;

					if (chunk.date >= _dates[0] && chunk.date <= _dates[_dates.length - 1]) {
						marginTop = _margin;
					}
				}

				$$invalidate(5, style = `width:calc(${chunk.days * 100}% + ${(chunk.days - 1) * 7}px);` + `margin-top:${marginTop}px;`);

				if (bgColor) {
					$$invalidate(5, style += `background-color:${bgColor};`);
				}

				if (txtColor) {
					$$invalidate(5, style += `color:${txtColor};`);
				}

				if (hidden) {
					$$invalidate(5, style += 'visibility:hidden;');
				}

				$$invalidate(4, classes = [
					$theme.event,
					...$_iClasses([], event),
					...createEventClasses($eventClassNames, event, $_view)
				].join(' '));
			}
		}

		if ($$self.$$.dirty[0] & /*$theme*/ 4 | $$self.$$.dirty[1] & /*chunk, $displayEventEnd, $eventContent, $_intlEventTime, $_view*/ 30728) {
			// Content
			$$invalidate(6, [timeText, content] = createEventContent(chunk, $displayEventEnd, $eventContent, $theme, $_intlEventTime, $_view), content);
		}

		if ($$self.$$.dirty[0] & /*display*/ 2 | $$self.$$.dirty[1] & /*$eventClick*/ 1024) {
			// Onclick handler
			$$invalidate(7, onclick = createHandler($eventClick, display));
		}
	};

	return [
		event,
		display,
		$theme,
		el,
		classes,
		style,
		content,
		onclick,
		$_interaction,
		$eventMouseEnter,
		$eventMouseLeave,
		dayMaxEvents,
		displayEventEnd,
		eventAllUpdated,
		eventBackgroundColor,
		eventTextColor,
		eventClick,
		eventColor,
		eventContent,
		eventClassNames,
		eventDidMount,
		eventMouseEnter,
		eventMouseLeave,
		theme,
		_view,
		_intlEventTime,
		_interaction,
		_iClasses,
		_resBgColor,
		_resTxtColor,
		_hiddenEvents,
		_popupDate,
		createHandler,
		createDragHandler,
		chunk,
		longChunks,
		inPopup,
		dates,
		reposition,
		margin,
		hidden,
		$eventClick,
		$_view,
		$_intlEventTime,
		$eventContent,
		$displayEventEnd,
		$eventClassNames,
		$_iClasses,
		$eventTextColor,
		$_resTxtColor,
		$eventColor,
		$eventBackgroundColor,
		$_resBgColor,
		article_binding
	];
}

class Event extends SvelteComponent {
	constructor(options) {
		super();

		init(
			this,
			options,
			instance$4,
			create_fragment$5,
			safe_not_equal,
			{
				chunk: 34,
				longChunks: 35,
				inPopup: 36,
				dates: 37,
				reposition: 38
			},
			null,
			[-1, -1, -1]
		);
	}

	get reposition() {
		return this.$$.ctx[38];
	}
}

/* packages/day-grid/src/Popup.svelte generated by Svelte v4.2.16 */

function get_each_context$3(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[20] = list[i];
	return child_ctx;
}

// (100:8) {#each $_popupChunks as chunk (chunk.event)}
function create_each_block$3(key_1, ctx) {
	let first;
	let event;
	let current;

	event = new Event({
			props: { chunk: /*chunk*/ ctx[20], inPopup: true }
		});

	return {
		key: key_1,
		first: null,
		c() {
			first = empty();
			create_component(event.$$.fragment);
			this.first = first;
		},
		m(target, anchor) {
			insert(target, first, anchor);
			mount_component(event, target, anchor);
			current = true;
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
			const event_changes = {};
			if (dirty & /*$_popupChunks*/ 1) event_changes.chunk = /*chunk*/ ctx[20];
			event.$set(event_changes);
		},
		i(local) {
			if (current) return;
			transition_in(event.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(event.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(first);
			}

			destroy_component(event, detaching);
		}
	};
}

function create_fragment$4(ctx) {
	let div2;
	let div0;
	let time;
	let time_datetime_value;
	let setContent_action;
	let t0;
	let a;
	let t1;
	let a_aria_label_value;
	let div0_class_value;
	let t2;
	let div1;
	let each_blocks = [];
	let each_1_lookup = new Map();
	let div1_class_value;
	let div2_class_value;
	let current;
	let mounted;
	let dispose;
	let each_value = ensure_array_like(/*$_popupChunks*/ ctx[0]);
	const get_key = ctx => /*chunk*/ ctx[20].event;

	for (let i = 0; i < each_value.length; i += 1) {
		let child_ctx = get_each_context$3(ctx, each_value, i);
		let key = get_key(child_ctx);
		each_1_lookup.set(key, each_blocks[i] = create_each_block$3(key, child_ctx));
	}

	return {
		c() {
			div2 = element("div");
			div0 = element("div");
			time = element("time");
			t0 = space();
			a = element("a");
			t1 = text("×");
			t2 = space();
			div1 = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(time, "datetime", time_datetime_value = toISOString(/*$_popupDate*/ ctx[3], 10));
			attr(a, "role", "button");
			attr(a, "tabindex", "0");
			attr(a, "aria-label", a_aria_label_value = /*$buttonText*/ ctx[6].close);
			attr(div0, "class", div0_class_value = /*$theme*/ ctx[4].dayHead);
			attr(div1, "class", div1_class_value = /*$theme*/ ctx[4].events);
			attr(div2, "class", div2_class_value = /*$theme*/ ctx[4].popup);
			attr(div2, "style", /*style*/ ctx[2]);
		},
		m(target, anchor) {
			insert(target, div2, anchor);
			append(div2, div0);
			append(div0, time);
			append(div0, t0);
			append(div0, a);
			append(a, t1);
			append(div2, t2);
			append(div2, div1);

			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(div1, null);
				}
			}

			/*div2_binding*/ ctx[16](div2);
			current = true;

			if (!mounted) {
				dispose = [
					action_destroyer(setContent_action = setContent.call(null, time, /*$_intlDayPopover*/ ctx[5].format(/*$_popupDate*/ ctx[3]))),
					listen(a, "click", stop_propagation(/*close*/ ctx[13])),
					listen(a, "keydown", keyEnter(/*close*/ ctx[13])),
					action_destroyer(outsideEvent.call(null, div2, 'pointerdown')),
					listen(div2, "pointerdown", stop_propagation(/*pointerdown_handler*/ ctx[15])),
					listen(div2, "pointerdownoutside", /*handlePointerDownOutside*/ ctx[14])
				];

				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (!current || dirty & /*$_popupDate*/ 8 && time_datetime_value !== (time_datetime_value = toISOString(/*$_popupDate*/ ctx[3], 10))) {
				attr(time, "datetime", time_datetime_value);
			}

			if (setContent_action && is_function(setContent_action.update) && dirty & /*$_intlDayPopover, $_popupDate*/ 40) setContent_action.update.call(null, /*$_intlDayPopover*/ ctx[5].format(/*$_popupDate*/ ctx[3]));

			if (!current || dirty & /*$buttonText*/ 64 && a_aria_label_value !== (a_aria_label_value = /*$buttonText*/ ctx[6].close)) {
				attr(a, "aria-label", a_aria_label_value);
			}

			if (!current || dirty & /*$theme*/ 16 && div0_class_value !== (div0_class_value = /*$theme*/ ctx[4].dayHead)) {
				attr(div0, "class", div0_class_value);
			}

			if (dirty & /*$_popupChunks*/ 1) {
				each_value = ensure_array_like(/*$_popupChunks*/ ctx[0]);
				group_outros();
				each_blocks = update_keyed_each(each_blocks, dirty, get_key, 1, ctx, each_value, each_1_lookup, div1, outro_and_destroy_block, create_each_block$3, null, get_each_context$3);
				check_outros();
			}

			if (!current || dirty & /*$theme*/ 16 && div1_class_value !== (div1_class_value = /*$theme*/ ctx[4].events)) {
				attr(div1, "class", div1_class_value);
			}

			if (!current || dirty & /*$theme*/ 16 && div2_class_value !== (div2_class_value = /*$theme*/ ctx[4].popup)) {
				attr(div2, "class", div2_class_value);
			}

			if (!current || dirty & /*style*/ 4) {
				attr(div2, "style", /*style*/ ctx[2]);
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(div2);
			}

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].d();
			}

			/*div2_binding*/ ctx[16](null);
			mounted = false;
			run_all(dispose);
		}
	};
}

function instance$3($$self, $$props, $$invalidate) {
	let $_interaction;
	let $_popupDate;
	let $_popupChunks;
	let $theme;
	let $_intlDayPopover;
	let $buttonText;
	let { buttonText, theme, _interaction, _intlDayPopover, _popupDate, _popupChunks } = getContext('state');
	component_subscribe($$self, buttonText, value => $$invalidate(6, $buttonText = value));
	component_subscribe($$self, theme, value => $$invalidate(4, $theme = value));
	component_subscribe($$self, _interaction, value => $$invalidate(17, $_interaction = value));
	component_subscribe($$self, _intlDayPopover, value => $$invalidate(5, $_intlDayPopover = value));
	component_subscribe($$self, _popupDate, value => $$invalidate(3, $_popupDate = value));
	component_subscribe($$self, _popupChunks, value => $$invalidate(0, $_popupChunks = value));
	let el;
	let style = '';

	function position() {
		let dayEl = ancestor(el, 1);
		let bodyEl = ancestor(dayEl, 3);
		let popupRect = rect(el);
		let dayRect = rect(dayEl);
		let bodyRect = rect(bodyEl);
		$$invalidate(2, style = '');
		let left;

		if (popupRect.width >= bodyRect.width) {
			left = bodyRect.left - dayRect.left;
			let right = dayRect.right - bodyRect.right;
			$$invalidate(2, style += `right:${right}px;`);
		} else {
			left = (dayRect.width - popupRect.width) / 2;

			if (dayRect.left + left < bodyRect.left) {
				left = bodyRect.left - dayRect.left;
			} else if (dayRect.left + left + popupRect.width > bodyRect.right) {
				left = bodyRect.right - dayRect.left - popupRect.width;
			}
		}

		$$invalidate(2, style += `left:${left}px;`);
		let top;

		if (popupRect.height >= bodyRect.height) {
			top = bodyRect.top - dayRect.top;
			let bottom = dayRect.bottom - bodyRect.bottom;
			$$invalidate(2, style += `bottom:${bottom}px;`);
		} else {
			top = (dayRect.height - popupRect.height) / 2;

			if (dayRect.top + top < bodyRect.top) {
				top = bodyRect.top - dayRect.top;
			} else if (dayRect.top + top + popupRect.height > bodyRect.bottom) {
				top = bodyRect.bottom - dayRect.top - popupRect.height;
			}
		}

		$$invalidate(2, style += `top:${top}px;`);
	}

	function reposition() {
		// Skip the first call (el is not defined at this time)
		if (el) {
			$$invalidate(2, style = '');

			// Let chunks to update/mount then position the popup
			tick().then(() => {
				if ($_popupChunks.length) {
					position();
				} else {
					close();
				}
			});
		}
	}

	function close(e) {
		set_store_value(_popupDate, $_popupDate = null, $_popupDate);
	}

	function handlePointerDownOutside(e) {
		close();
		$_interaction.action?.noClick();
	}

	function pointerdown_handler(event) {
		bubble.call(this, $$self, event);
	}

	function div2_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			el = $$value;
			$$invalidate(1, el);
		});
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*$_popupChunks*/ 1) {
			if ($_popupChunks) {
				// Fire reposition only on popup chunks change
				reposition();
			}
		}
	};

	return [
		$_popupChunks,
		el,
		style,
		$_popupDate,
		$theme,
		$_intlDayPopover,
		$buttonText,
		buttonText,
		theme,
		_interaction,
		_intlDayPopover,
		_popupDate,
		_popupChunks,
		close,
		handlePointerDownOutside,
		pointerdown_handler,
		div2_binding
	];
}

class Popup extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance$3, create_fragment$4, safe_not_equal, {});
	}
}

/* packages/day-grid/src/Day.svelte generated by Svelte v4.2.16 */

function get_each_context$2(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[42] = list[i];
	child_ctx[43] = list;
	child_ctx[44] = i;
	return child_ctx;
}

// (103:4) {#if iChunks[1] && datesEqual(iChunks[1].date, date)}
function create_if_block_3(ctx) {
	let div;
	let event;
	let div_class_value;
	let current;
	event = new Event({ props: { chunk: /*iChunks*/ ctx[2][1] } });

	return {
		c() {
			div = element("div");
			create_component(event.$$.fragment);
			attr(div, "class", div_class_value = /*$theme*/ ctx[13].events);
		},
		m(target, anchor) {
			insert(target, div, anchor);
			mount_component(event, div, null);
			current = true;
		},
		p(ctx, dirty) {
			const event_changes = {};
			if (dirty[0] & /*iChunks*/ 4) event_changes.chunk = /*iChunks*/ ctx[2][1];
			event.$set(event_changes);

			if (!current || dirty[0] & /*$theme*/ 8192 && div_class_value !== (div_class_value = /*$theme*/ ctx[13].events)) {
				attr(div, "class", div_class_value);
			}
		},
		i(local) {
			if (current) return;
			transition_in(event.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(event.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(div);
			}

			destroy_component(event);
		}
	};
}

// (109:4) {#if iChunks[0] && datesEqual(iChunks[0].date, date)}
function create_if_block_2(ctx) {
	let div;
	let event;
	let div_class_value;
	let current;
	event = new Event({ props: { chunk: /*iChunks*/ ctx[2][0] } });

	return {
		c() {
			div = element("div");
			create_component(event.$$.fragment);
			attr(div, "class", div_class_value = "" + (/*$theme*/ ctx[13].events + " " + /*$theme*/ ctx[13].preview));
		},
		m(target, anchor) {
			insert(target, div, anchor);
			mount_component(event, div, null);
			current = true;
		},
		p(ctx, dirty) {
			const event_changes = {};
			if (dirty[0] & /*iChunks*/ 4) event_changes.chunk = /*iChunks*/ ctx[2][0];
			event.$set(event_changes);

			if (!current || dirty[0] & /*$theme*/ 8192 && div_class_value !== (div_class_value = "" + (/*$theme*/ ctx[13].events + " " + /*$theme*/ ctx[13].preview))) {
				attr(div, "class", div_class_value);
			}
		},
		i(local) {
			if (current) return;
			transition_in(event.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(event.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(div);
			}

			destroy_component(event);
		}
	};
}

// (115:8) {#each dayChunks as chunk, i (chunk.event)}
function create_each_block$2(key_1, ctx) {
	let first;
	let event;
	let i = /*i*/ ctx[44];
	let current;
	const assign_event = () => /*event_binding*/ ctx[36](event, i);
	const unassign_event = () => /*event_binding*/ ctx[36](null, i);

	let event_props = {
		chunk: /*chunk*/ ctx[42],
		longChunks: /*longChunks*/ ctx[1],
		dates: /*dates*/ ctx[3]
	};

	event = new Event({ props: event_props });
	assign_event();

	return {
		key: key_1,
		first: null,
		c() {
			first = empty();
			create_component(event.$$.fragment);
			this.first = first;
		},
		m(target, anchor) {
			insert(target, first, anchor);
			mount_component(event, target, anchor);
			current = true;
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;

			if (i !== /*i*/ ctx[44]) {
				unassign_event();
				i = /*i*/ ctx[44];
				assign_event();
			}

			const event_changes = {};
			if (dirty[0] & /*dayChunks*/ 32) event_changes.chunk = /*chunk*/ ctx[42];
			if (dirty[0] & /*longChunks*/ 2) event_changes.longChunks = /*longChunks*/ ctx[1];
			if (dirty[0] & /*dates*/ 8) event_changes.dates = /*dates*/ ctx[3];
			event.$set(event_changes);
		},
		i(local) {
			if (current) return;
			transition_in(event.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(event.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(first);
			}

			unassign_event();
			destroy_component(event, detaching);
		}
	};
}

// (119:4) {#if showPopup}
function create_if_block_1(ctx) {
	let popup;
	let current;
	popup = new Popup({});

	return {
		c() {
			create_component(popup.$$.fragment);
		},
		m(target, anchor) {
			mount_component(popup, target, anchor);
			current = true;
		},
		i(local) {
			if (current) return;
			transition_in(popup.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(popup.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(popup, detaching);
		}
	};
}

// (123:8) {#if hiddenEvents.size}
function create_if_block(ctx) {
	let a;
	let setContent_action;
	let mounted;
	let dispose;

	return {
		c() {
			a = element("a");
			attr(a, "role", "button");
			attr(a, "tabindex", "0");
			attr(a, "aria-haspopup", "true");
		},
		m(target, anchor) {
			insert(target, a, anchor);

			if (!mounted) {
				dispose = [
					listen(a, "click", stop_propagation(/*showMore*/ ctx[26])),
					listen(a, "keydown", keyEnter(/*showMore*/ ctx[26])),
					listen(a, "pointerdown", stop_propagation(/*pointerdown_handler*/ ctx[35])),
					action_destroyer(setContent_action = setContent.call(null, a, /*moreLink*/ ctx[11]))
				];

				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (setContent_action && is_function(setContent_action.update) && dirty[0] & /*moreLink*/ 2048) setContent_action.update.call(null, /*moreLink*/ ctx[11]);
		},
		d(detaching) {
			if (detaching) {
				detach(a);
			}

			mounted = false;
			run_all(dispose);
		}
	};
}

function create_fragment$3(ctx) {
	let div2;
	let time;
	let time_class_value;
	let time_datetime_value;
	let setContent_action;
	let t0;
	let show_if_1 = /*iChunks*/ ctx[2][1] && datesEqual(/*iChunks*/ ctx[2][1].date, /*date*/ ctx[0]);
	let t1;
	let show_if = /*iChunks*/ ctx[2][0] && datesEqual(/*iChunks*/ ctx[2][0].date, /*date*/ ctx[0]);
	let t2;
	let div0;
	let each_blocks = [];
	let each_1_lookup = new Map();
	let div0_class_value;
	let t3;
	let t4;
	let div1;
	let div1_class_value;
	let div2_class_value;
	let current;
	let mounted;
	let dispose;
	let if_block0 = show_if_1 && create_if_block_3(ctx);
	let if_block1 = show_if && create_if_block_2(ctx);
	let each_value = ensure_array_like(/*dayChunks*/ ctx[5]);
	const get_key = ctx => /*chunk*/ ctx[42].event;

	for (let i = 0; i < each_value.length; i += 1) {
		let child_ctx = get_each_context$2(ctx, each_value, i);
		let key = get_key(child_ctx);
		each_1_lookup.set(key, each_blocks[i] = create_each_block$2(key, child_ctx));
	}

	let if_block2 = /*showPopup*/ ctx[7] && create_if_block_1();
	let if_block3 = /*hiddenEvents*/ ctx[6].size && create_if_block(ctx);

	return {
		c() {
			div2 = element("div");
			time = element("time");
			t0 = space();
			if (if_block0) if_block0.c();
			t1 = space();
			if (if_block1) if_block1.c();
			t2 = space();
			div0 = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t3 = space();
			if (if_block2) if_block2.c();
			t4 = space();
			div1 = element("div");
			if (if_block3) if_block3.c();
			attr(time, "class", time_class_value = /*$theme*/ ctx[13].dayHead);
			attr(time, "datetime", time_datetime_value = toISOString(/*date*/ ctx[0], 10));
			attr(div0, "class", div0_class_value = /*$theme*/ ctx[13].events);
			attr(div1, "class", div1_class_value = /*$theme*/ ctx[13].dayFoot);

			attr(div2, "class", div2_class_value = "" + (/*$theme*/ ctx[13].day + " " + /*$theme*/ ctx[13].weekdays?.[/*date*/ ctx[0].getUTCDay()] + (/*isToday*/ ctx[8] ? ' ' + /*$theme*/ ctx[13].today : '') + (/*otherMonth*/ ctx[9]
			? ' ' + /*$theme*/ ctx[13].otherMonth
			: '') + (/*highlight*/ ctx[10]
			? ' ' + /*$theme*/ ctx[13].highlight
			: '')));

			attr(div2, "role", "cell");
		},
		m(target, anchor) {
			insert(target, div2, anchor);
			append(div2, time);
			append(div2, t0);
			if (if_block0) if_block0.m(div2, null);
			append(div2, t1);
			if (if_block1) if_block1.m(div2, null);
			append(div2, t2);
			append(div2, div0);

			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(div0, null);
				}
			}

			append(div2, t3);
			if (if_block2) if_block2.m(div2, null);
			append(div2, t4);
			append(div2, div1);
			if (if_block3) if_block3.m(div1, null);
			/*div2_binding*/ ctx[37](div2);
			current = true;

			if (!mounted) {
				dispose = [
					action_destroyer(setContent_action = setContent.call(null, time, /*$_intlDayCell*/ ctx[15].format(/*date*/ ctx[0]))),
					listen(div2, "pointerleave", function () {
						if (is_function(/*$_interaction*/ ctx[14].pointer?.leave)) /*$_interaction*/ ctx[14].pointer?.leave.apply(this, arguments);
					}),
					listen(div2, "pointerdown", function () {
						if (is_function(/*$_interaction*/ ctx[14].action?.select)) /*$_interaction*/ ctx[14].action?.select.apply(this, arguments);
					})
				];

				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;

			if (!current || dirty[0] & /*$theme*/ 8192 && time_class_value !== (time_class_value = /*$theme*/ ctx[13].dayHead)) {
				attr(time, "class", time_class_value);
			}

			if (!current || dirty[0] & /*date*/ 1 && time_datetime_value !== (time_datetime_value = toISOString(/*date*/ ctx[0], 10))) {
				attr(time, "datetime", time_datetime_value);
			}

			if (setContent_action && is_function(setContent_action.update) && dirty[0] & /*$_intlDayCell, date*/ 32769) setContent_action.update.call(null, /*$_intlDayCell*/ ctx[15].format(/*date*/ ctx[0]));
			if (dirty[0] & /*iChunks, date*/ 5) show_if_1 = /*iChunks*/ ctx[2][1] && datesEqual(/*iChunks*/ ctx[2][1].date, /*date*/ ctx[0]);

			if (show_if_1) {
				if (if_block0) {
					if_block0.p(ctx, dirty);

					if (dirty[0] & /*iChunks, date*/ 5) {
						transition_in(if_block0, 1);
					}
				} else {
					if_block0 = create_if_block_3(ctx);
					if_block0.c();
					transition_in(if_block0, 1);
					if_block0.m(div2, t1);
				}
			} else if (if_block0) {
				group_outros();

				transition_out(if_block0, 1, 1, () => {
					if_block0 = null;
				});

				check_outros();
			}

			if (dirty[0] & /*iChunks, date*/ 5) show_if = /*iChunks*/ ctx[2][0] && datesEqual(/*iChunks*/ ctx[2][0].date, /*date*/ ctx[0]);

			if (show_if) {
				if (if_block1) {
					if_block1.p(ctx, dirty);

					if (dirty[0] & /*iChunks, date*/ 5) {
						transition_in(if_block1, 1);
					}
				} else {
					if_block1 = create_if_block_2(ctx);
					if_block1.c();
					transition_in(if_block1, 1);
					if_block1.m(div2, t2);
				}
			} else if (if_block1) {
				group_outros();

				transition_out(if_block1, 1, 1, () => {
					if_block1 = null;
				});

				check_outros();
			}

			if (dirty[0] & /*dayChunks, longChunks, dates, refs*/ 4138) {
				each_value = ensure_array_like(/*dayChunks*/ ctx[5]);
				group_outros();
				each_blocks = update_keyed_each(each_blocks, dirty, get_key, 1, ctx, each_value, each_1_lookup, div0, outro_and_destroy_block, create_each_block$2, null, get_each_context$2);
				check_outros();
			}

			if (!current || dirty[0] & /*$theme*/ 8192 && div0_class_value !== (div0_class_value = /*$theme*/ ctx[13].events)) {
				attr(div0, "class", div0_class_value);
			}

			if (/*showPopup*/ ctx[7]) {
				if (if_block2) {
					if (dirty[0] & /*showPopup*/ 128) {
						transition_in(if_block2, 1);
					}
				} else {
					if_block2 = create_if_block_1();
					if_block2.c();
					transition_in(if_block2, 1);
					if_block2.m(div2, t4);
				}
			} else if (if_block2) {
				group_outros();

				transition_out(if_block2, 1, 1, () => {
					if_block2 = null;
				});

				check_outros();
			}

			if (/*hiddenEvents*/ ctx[6].size) {
				if (if_block3) {
					if_block3.p(ctx, dirty);
				} else {
					if_block3 = create_if_block(ctx);
					if_block3.c();
					if_block3.m(div1, null);
				}
			} else if (if_block3) {
				if_block3.d(1);
				if_block3 = null;
			}

			if (!current || dirty[0] & /*$theme*/ 8192 && div1_class_value !== (div1_class_value = /*$theme*/ ctx[13].dayFoot)) {
				attr(div1, "class", div1_class_value);
			}

			if (!current || dirty[0] & /*$theme, date, isToday, otherMonth, highlight*/ 9985 && div2_class_value !== (div2_class_value = "" + (/*$theme*/ ctx[13].day + " " + /*$theme*/ ctx[13].weekdays?.[/*date*/ ctx[0].getUTCDay()] + (/*isToday*/ ctx[8] ? ' ' + /*$theme*/ ctx[13].today : '') + (/*otherMonth*/ ctx[9]
			? ' ' + /*$theme*/ ctx[13].otherMonth
			: '') + (/*highlight*/ ctx[10]
			? ' ' + /*$theme*/ ctx[13].highlight
			: '')))) {
				attr(div2, "class", div2_class_value);
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block0);
			transition_in(if_block1);

			for (let i = 0; i < each_value.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			transition_in(if_block2);
			current = true;
		},
		o(local) {
			transition_out(if_block0);
			transition_out(if_block1);

			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			transition_out(if_block2);
			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(div2);
			}

			if (if_block0) if_block0.d();
			if (if_block1) if_block1.d();

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].d();
			}

			if (if_block2) if_block2.d();
			if (if_block3) if_block3.d();
			/*div2_binding*/ ctx[37](null);
			mounted = false;
			run_all(dispose);
		}
	};
}

function instance$2($$self, $$props, $$invalidate) {
	let $_popupChunks;
	let $_popupDate;
	let $moreLinkContent;
	let $_hiddenEvents;
	let $highlightedDates;
	let $currentDate;
	let $_today;
	let $theme;
	let $_interaction;
	let $_intlDayCell;
	let { date } = $$props;
	let { chunks } = $$props;
	let { longChunks } = $$props;
	let { iChunks = [] } = $$props;
	let { dates } = $$props;
	let { date: currentDate, dayMaxEvents, highlightedDates, moreLinkContent, theme, _hiddenEvents, _intlDayCell, _popupDate, _popupChunks, _today, _interaction, _queue } = getContext('state');
	component_subscribe($$self, currentDate, value => $$invalidate(33, $currentDate = value));
	component_subscribe($$self, highlightedDates, value => $$invalidate(32, $highlightedDates = value));
	component_subscribe($$self, moreLinkContent, value => $$invalidate(30, $moreLinkContent = value));
	component_subscribe($$self, theme, value => $$invalidate(13, $theme = value));
	component_subscribe($$self, _hiddenEvents, value => $$invalidate(31, $_hiddenEvents = value));
	component_subscribe($$self, _intlDayCell, value => $$invalidate(15, $_intlDayCell = value));
	component_subscribe($$self, _popupDate, value => $$invalidate(29, $_popupDate = value));
	component_subscribe($$self, _popupChunks, value => $$invalidate(38, $_popupChunks = value));
	component_subscribe($$self, _today, value => $$invalidate(34, $_today = value));
	component_subscribe($$self, _interaction, value => $$invalidate(14, $_interaction = value));
	let el;
	let dayChunks;
	let isToday;
	let otherMonth;
	let highlight;
	let hiddenEvents = new Set(); // hidden events of this day
	let moreLink = '';
	let showPopup;
	let refs = [];

	function showMore() {
		set_store_value(_popupDate, $_popupDate = date, $_popupDate);
	}

	function setPopupChunks() {
		let nextDay = addDay(cloneDate(date));
		let chunks = dayChunks.concat(longChunks[date.getTime()]?.chunks || []);
		set_store_value(_popupChunks, $_popupChunks = chunks.map(chunk => assign({}, chunk, createEventChunk(chunk.event, date, nextDay), { days: 1, dates: [date] })).sort((a, b) => a.top - b.top), $_popupChunks);
	}

	function reposition() {
		runReposition(refs, dayChunks);
	}

	function pointerdown_handler(event) {
		bubble.call(this, $$self, event);
	}

	function event_binding($$value, i) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			refs[i] = $$value;
			$$invalidate(12, refs);
		});
	}

	function div2_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			el = $$value;
			$$invalidate(4, el);
		});
	}

	$$self.$$set = $$props => {
		if ('date' in $$props) $$invalidate(0, date = $$props.date);
		if ('chunks' in $$props) $$invalidate(27, chunks = $$props.chunks);
		if ('longChunks' in $$props) $$invalidate(1, longChunks = $$props.longChunks);
		if ('iChunks' in $$props) $$invalidate(2, iChunks = $$props.iChunks);
		if ('dates' in $$props) $$invalidate(3, dates = $$props.dates);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty[0] & /*hiddenEvents, chunks, date, dayChunks*/ 134217825) {
			{
				$$invalidate(5, dayChunks = []);
				hiddenEvents.clear();
				((($$invalidate(6, hiddenEvents), $$invalidate(27, chunks)), $$invalidate(0, date)), $$invalidate(5, dayChunks));

				for (let chunk of chunks) {
					if (datesEqual(chunk.date, date)) {
						dayChunks.push(chunk);
					} // if ($dayMaxEvents !== false && dayChunks.length > $dayMaxEvents) {
					// 	chunk.hidden = true;
				} // }
			}
		}

		if ($$self.$$.dirty[0] & /*date, hiddenEvents*/ 65) {
			set_store_value(_hiddenEvents, $_hiddenEvents[date.getTime()] = hiddenEvents, $_hiddenEvents);
		}

		if ($$self.$$.dirty[0] & /*date*/ 1 | $$self.$$.dirty[1] & /*$_today*/ 8) {
			$$invalidate(8, isToday = datesEqual(date, $_today));
		}

		if ($$self.$$.dirty[0] & /*date*/ 1 | $$self.$$.dirty[1] & /*$currentDate, $highlightedDates*/ 6) {
			{
				$$invalidate(9, otherMonth = date.getUTCMonth() !== $currentDate.getUTCMonth());
				$$invalidate(10, highlight = $highlightedDates.some(d => datesEqual(d, date)));
			}
		}

		if ($$self.$$.dirty[0] & /*hiddenEvents, $moreLinkContent*/ 1073741888 | $$self.$$.dirty[1] & /*$_hiddenEvents*/ 1) {
			if ($_hiddenEvents && hiddenEvents.size) {
				// make Svelte update this block on $_hiddenEvents update
				let text = '+' + hiddenEvents.size + ' more';

				if ($moreLinkContent) {
					$$invalidate(11, moreLink = is_function($moreLinkContent)
					? $moreLinkContent({ num: hiddenEvents.size, text })
					: $moreLinkContent);
				} else {
					$$invalidate(11, moreLink = text);
				}
			}
		}

		if ($$self.$$.dirty[0] & /*$_popupDate, date*/ 536870913) {
			$$invalidate(7, showPopup = $_popupDate && datesEqual(date, $_popupDate));
		}

		if ($$self.$$.dirty[0] & /*showPopup, longChunks, dayChunks*/ 162) {
			if (showPopup && longChunks && dayChunks) {
				// Let chunks to reposition then set popup chunks
				tick().then(setPopupChunks);
			}
		}

		if ($$self.$$.dirty[0] & /*el, date*/ 17) {
			// dateFromPoint
			if (el) {
				setPayload(el, () => ({
					allDay: true,
					date,
					resource: undefined,
					dayEl: el
				}));
			}
		}
	};

	return [
		date,
		longChunks,
		iChunks,
		dates,
		el,
		dayChunks,
		hiddenEvents,
		showPopup,
		isToday,
		otherMonth,
		highlight,
		moreLink,
		refs,
		$theme,
		$_interaction,
		$_intlDayCell,
		currentDate,
		highlightedDates,
		moreLinkContent,
		theme,
		_hiddenEvents,
		_intlDayCell,
		_popupDate,
		_popupChunks,
		_today,
		_interaction,
		showMore,
		chunks,
		reposition,
		$_popupDate,
		$moreLinkContent,
		$_hiddenEvents,
		$highlightedDates,
		$currentDate,
		$_today,
		pointerdown_handler,
		event_binding,
		div2_binding
	];
}

class Day extends SvelteComponent {
	constructor(options) {
		super();

		init(
			this,
			options,
			instance$2,
			create_fragment$3,
			safe_not_equal,
			{
				date: 0,
				chunks: 27,
				longChunks: 1,
				iChunks: 2,
				dates: 3,
				reposition: 28
			},
			null,
			[-1, -1]
		);
	}

	get reposition() {
		return this.$$.ctx[28];
	}
}

/* packages/day-grid/src/Week.svelte generated by Svelte v4.2.16 */

function get_each_context$1(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[21] = list[i];
	child_ctx[22] = list;
	child_ctx[23] = i;
	return child_ctx;
}

// (58:4) {#each dates as date, i}
function create_each_block$1(ctx) {
	let day;
	let i = /*i*/ ctx[23];
	let current;
	const assign_day = () => /*day_binding*/ ctx[18](day, i);
	const unassign_day = () => /*day_binding*/ ctx[18](null, i);

	let day_props = {
		date: /*date*/ ctx[21],
		chunks: /*chunks*/ ctx[1],
		longChunks: /*longChunks*/ ctx[2],
		iChunks: /*iChunks*/ ctx[3],
		dates: /*dates*/ ctx[0]
	};

	day = new Day({ props: day_props });
	assign_day();

	return {
		c() {
			create_component(day.$$.fragment);
		},
		m(target, anchor) {
			mount_component(day, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			if (i !== /*i*/ ctx[23]) {
				unassign_day();
				i = /*i*/ ctx[23];
				assign_day();
			}

			const day_changes = {};
			if (dirty & /*dates*/ 1) day_changes.date = /*date*/ ctx[21];
			if (dirty & /*chunks*/ 2) day_changes.chunks = /*chunks*/ ctx[1];
			if (dirty & /*longChunks*/ 4) day_changes.longChunks = /*longChunks*/ ctx[2];
			if (dirty & /*iChunks*/ 8) day_changes.iChunks = /*iChunks*/ ctx[3];
			if (dirty & /*dates*/ 1) day_changes.dates = /*dates*/ ctx[0];
			day.$set(day_changes);
		},
		i(local) {
			if (current) return;
			transition_in(day.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(day.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			unassign_day();
			destroy_component(day, detaching);
		}
	};
}

function create_fragment$2(ctx) {
	let div;
	let div_class_value;
	let current;
	let mounted;
	let dispose;
	let each_value = ensure_array_like(/*dates*/ ctx[0]);
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block$1(get_each_context$1(ctx, each_value, i));
	}

	const out = i => transition_out(each_blocks[i], 1, 1, () => {
		each_blocks[i] = null;
	});

	return {
		c() {
			div = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(div, "class", div_class_value = /*$theme*/ ctx[5].days);
			attr(div, "role", "row");
		},
		m(target, anchor) {
			insert(target, div, anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(div, null);
				}
			}

			current = true;

			if (!mounted) {
				dispose = listen(window, "resize", /*reposition*/ ctx[11]);
				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (dirty & /*dates, chunks, longChunks, iChunks, refs*/ 31) {
				each_value = ensure_array_like(/*dates*/ ctx[0]);
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context$1(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
						transition_in(each_blocks[i], 1);
					} else {
						each_blocks[i] = create_each_block$1(child_ctx);
						each_blocks[i].c();
						transition_in(each_blocks[i], 1);
						each_blocks[i].m(div, null);
					}
				}

				group_outros();

				for (i = each_value.length; i < each_blocks.length; i += 1) {
					out(i);
				}

				check_outros();
			}

			if (!current || dirty & /*$theme*/ 32 && div_class_value !== (div_class_value = /*$theme*/ ctx[5].days)) {
				attr(div, "class", div_class_value);
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			each_blocks = each_blocks.filter(Boolean);

			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(div);
			}

			destroy_each(each_blocks, detaching);
			mounted = false;
			dispose();
		}
	};
}

function instance$1($$self, $$props, $$invalidate) {
	let $_hiddenEvents;
	let $hiddenDays;
	let $_iEvents;
	let $_events;
	let $theme;
	let { dates } = $$props;
	let { _events, _iEvents, _queue2, _hiddenEvents, hiddenDays, theme } = getContext('state');
	component_subscribe($$self, _events, value => $$invalidate(17, $_events = value));
	component_subscribe($$self, _iEvents, value => $$invalidate(16, $_iEvents = value));
	component_subscribe($$self, _hiddenEvents, value => $$invalidate(14, $_hiddenEvents = value));
	component_subscribe($$self, hiddenDays, value => $$invalidate(15, $hiddenDays = value));
	component_subscribe($$self, theme, value => $$invalidate(5, $theme = value));
	let chunks, longChunks, iChunks = [];
	let start;
	let end;
	let refs = [];
	let debounceHandle = {};

	function reposition() {
		debounce(() => runReposition(refs, dates), debounceHandle, _queue2);
	}

	function day_binding($$value, i) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			refs[i] = $$value;
			$$invalidate(4, refs);
		});
	}

	$$self.$$set = $$props => {
		if ('dates' in $$props) $$invalidate(0, dates = $$props.dates);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*dates*/ 1) {
			{
				$$invalidate(12, start = dates[0]);
				$$invalidate(13, end = addDay(cloneDate(dates[dates.length - 1])));
			}
		}

		if ($$self.$$.dirty & /*$_events, start, end, chunks, $hiddenDays*/ 176130) {
			{
				$$invalidate(1, chunks = []);

				for (let event of $_events) {
					if (!bgEvent(event.display) && eventIntersects(event, start, end)) {
						let chunk = createEventChunk(event, start, end);
						chunks.push(chunk);
					}
				}

				$$invalidate(2, longChunks = prepareEventChunks(chunks, $hiddenDays));

				// Run reposition only when events get changed
				reposition();
			}
		}

		if ($$self.$$.dirty & /*$_iEvents, start, end, $hiddenDays*/ 110592) {
			$$invalidate(3, iChunks = $_iEvents.map(event => {
				let chunk;

				if (event && eventIntersects(event, start, end)) {
					chunk = createEventChunk(event, start, end);
					prepareEventChunks([chunk], $hiddenDays);
				} else {
					chunk = null;
				}

				return chunk;
			}));
		}

		if ($$self.$$.dirty & /*$_hiddenEvents*/ 16384) {
			if ($_hiddenEvents) {
				// Schedule reposition during next update
				tick().then(reposition);
			}
		}
	};

	return [
		dates,
		chunks,
		longChunks,
		iChunks,
		refs,
		$theme,
		_events,
		_iEvents,
		_hiddenEvents,
		hiddenDays,
		theme,
		reposition,
		start,
		end,
		$_hiddenEvents,
		$hiddenDays,
		$_iEvents,
		$_events,
		day_binding
	];
}

class Week extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance$1, create_fragment$2, safe_not_equal, { dates: 0 });
	}
}

/* packages/day-grid/src/Body.svelte generated by Svelte v4.2.16 */

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[15] = list[i];
	return child_ctx;
}

// (30:8) {#each weeks as dates}
function create_each_block(ctx) {
	let week;
	let current;
	week = new Week({ props: { dates: /*dates*/ ctx[15] } });

	return {
		c() {
			create_component(week.$$.fragment);
		},
		m(target, anchor) {
			mount_component(week, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const week_changes = {};
			if (dirty & /*weeks*/ 1) week_changes.dates = /*dates*/ ctx[15];
			week.$set(week_changes);
		},
		i(local) {
			if (current) return;
			transition_in(week.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(week.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(week, detaching);
		}
	};
}

function create_fragment$1(ctx) {
	let div1;
	let div0;
	let div0_class_value;
	let div1_class_value;
	let current;
	let each_value = ensure_array_like(/*weeks*/ ctx[0]);
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	const out = i => transition_out(each_blocks[i], 1, 1, () => {
		each_blocks[i] = null;
	});

	return {
		c() {
			div1 = element("div");
			div0 = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(div0, "class", div0_class_value = /*$theme*/ ctx[2].content);

			attr(div1, "class", div1_class_value = "" + (/*$theme*/ ctx[2].body + (/*$dayMaxEvents*/ ctx[1] === true
			? ' ' + /*$theme*/ ctx[2].uniform
			: '')));
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			append(div1, div0);

			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(div0, null);
				}
			}

			/*div1_binding*/ ctx[13](div1);
			current = true;
		},
		p(ctx, [dirty]) {
			if (dirty & /*weeks*/ 1) {
				each_value = ensure_array_like(/*weeks*/ ctx[0]);
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
						transition_in(each_blocks[i], 1);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						transition_in(each_blocks[i], 1);
						each_blocks[i].m(div0, null);
					}
				}

				group_outros();

				for (i = each_value.length; i < each_blocks.length; i += 1) {
					out(i);
				}

				check_outros();
			}

			if (!current || dirty & /*$theme*/ 4 && div0_class_value !== (div0_class_value = /*$theme*/ ctx[2].content)) {
				attr(div0, "class", div0_class_value);
			}

			if (!current || dirty & /*$theme, $dayMaxEvents*/ 6 && div1_class_value !== (div1_class_value = "" + (/*$theme*/ ctx[2].body + (/*$dayMaxEvents*/ ctx[1] === true
			? ' ' + /*$theme*/ ctx[2].uniform
			: '')))) {
				attr(div1, "class", div1_class_value);
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			each_blocks = each_blocks.filter(Boolean);

			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(div1);
			}

			destroy_each(each_blocks, detaching);
			/*div1_binding*/ ctx[13](null);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $_viewDates;
	let $dayMaxEvents;
	let $_hiddenEvents;
	let $hiddenDays;
	let $theme;
	let $_bodyEl;
	let { _bodyEl, _viewDates, _hiddenEvents, dayMaxEvents, hiddenDays, theme } = getContext('state');
	component_subscribe($$self, _bodyEl, value => $$invalidate(3, $_bodyEl = value));
	component_subscribe($$self, _viewDates, value => $$invalidate(11, $_viewDates = value));
	component_subscribe($$self, _hiddenEvents, value => $$invalidate(14, $_hiddenEvents = value));
	component_subscribe($$self, dayMaxEvents, value => $$invalidate(1, $dayMaxEvents = value));
	component_subscribe($$self, hiddenDays, value => $$invalidate(12, $hiddenDays = value));
	component_subscribe($$self, theme, value => $$invalidate(2, $theme = value));
	let weeks;
	let days;

	function div1_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			$_bodyEl = $$value;
			_bodyEl.set($_bodyEl);
		});
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*$hiddenDays, $dayMaxEvents, $_viewDates, days, weeks*/ 7171) {
			{
				$$invalidate(0, weeks = []);
				$$invalidate(10, days = 7 - $hiddenDays.length);
				set_store_value(_hiddenEvents, $_hiddenEvents = {}, $_hiddenEvents);

				for (let i = 0; i < $_viewDates.length / days; ++i) {
					let dates = [];

					for (let j = 0; j < days; ++j) {
						dates.push($_viewDates[i * days + j]);
					}

					weeks.push(dates);
				}
			}
		}
	};

	return [
		weeks,
		$dayMaxEvents,
		$theme,
		$_bodyEl,
		_bodyEl,
		_viewDates,
		_hiddenEvents,
		dayMaxEvents,
		hiddenDays,
		theme,
		days,
		$_viewDates,
		$hiddenDays,
		div1_binding
	];
}

class Body extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment$1, safe_not_equal, {});
	}
}

/* packages/day-grid/src/View.svelte generated by Svelte v4.2.16 */

function create_fragment(ctx) {
	let header;
	let t;
	let body;
	let current;
	header = new Header({});
	body = new Body({});

	return {
		c() {
			create_component(header.$$.fragment);
			t = space();
			create_component(body.$$.fragment);
		},
		m(target, anchor) {
			mount_component(header, target, anchor);
			insert(target, t, anchor);
			mount_component(body, target, anchor);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(header.$$.fragment, local);
			transition_in(body.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(header.$$.fragment, local);
			transition_out(body.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(t);
			}

			destroy_component(header, detaching);
			destroy_component(body, detaching);
		}
	};
}

class View extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, null, create_fragment, safe_not_equal, {});
	}
}

var index = {
	createOptions(options) {
		options.dayMaxEvents = false;
		options.dayCellFormat = {day: 'numeric'};
		options.dayPopoverFormat = {month: 'long', day: 'numeric', year: 'numeric'};
		options.moreLinkContent = undefined;
		// Common options
		options.buttonText.dayGridMonth = 'month';
		options.buttonText.close = 'Close';
		options.theme.uniform = 'ec-uniform';
		options.theme.dayFoot = 'ec-day-foot';
		options.theme.popup = 'ec-popup';
		options.view = 'dayGridMonth';
		options.views.dayGridMonth = {
			buttonText: btnTextMonth,
			component: View,
			dayHeaderFormat: {weekday: 'short'},
			dayHeaderAriaLabelFormat: {weekday: 'long'},
			displayEventEnd: false,
			duration: {months: 1},
			theme: themeView('ec-day-grid ec-month-view'),
			titleFormat: {year: 'numeric', month: 'long'}
		};
	},

	createStores(state) {
		state._days = days(state);
		state._intlDayCell = intl(state.locale, state.dayCellFormat);
		state._intlDayPopover = intl(state.locale, state.dayPopoverFormat);
		state._hiddenEvents = writable({});
		state._popupDate = writable(null);
		state._popupChunks = writable([]);
	}
};

export { index as default };
